import { PositionNotificationEnum, TypeNotificationEnum } from "@siigo/siigo-library-enums";
import { INotification } from "@siigo/siigo-library-models";
import { SiigoNotificationGrowlAtom } from "@siigo/siigo-notification-growl-atom-angular";

export class Utils {

  //Format date
  public static formatDate(date: string) {
    let newDate;
    let dateBack = new Date(date);
    let year = new Intl.DateTimeFormat('es', { year: 'numeric' }).format(dateBack);
    let month = new Intl.DateTimeFormat('es', { month: 'short' }).format(dateBack);
    let day = new Intl.DateTimeFormat('es', { day: '2-digit' }).format(dateBack);
    let hour = new Intl.DateTimeFormat('es', { hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(dateBack);
    newDate = `${day} ${month} ${year} ${hour}`;
    return newDate;
  }

    //Format date local time
    public static formatFromUtcToLocalDate(date: string) {
      let newDate;
      let dateBack = new Date(date + 'Z');
      let year = new Intl.DateTimeFormat('es', { year: 'numeric' }).format(dateBack);
      let month = new Intl.DateTimeFormat('es', { month: 'short' }).format(dateBack);
      let day = new Intl.DateTimeFormat('es', { day: '2-digit' }).format(dateBack);
      let hour = new Intl.DateTimeFormat('es', { hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(dateBack);
      newDate = `${day} ${month.charAt(0).toUpperCase() + month.slice(1) + '.'} ${year} `;
      return newDate;
    }

  //Format date dd/MMM/y. Example: 29/Oct./2022
  public static customDate(date: string) {
    const local = 'es';
    let dateBack = new Date(date);
    let year = new Intl.DateTimeFormat(local, { year: 'numeric' }).format(dateBack);
    let month = new Intl.DateTimeFormat(local, { month: 'short' }).format(dateBack);
    let day = new Intl.DateTimeFormat(local, { day: '2-digit' }).format(dateBack);
    month = this.capitalizeFirstLetter(month);

    return `${day}/${month}./${year}`;
  }

  //Format date dd MMM YYYY - HH:mm. Example: 27 Oct. 2022 - 15:32
  public static formatDateHistory(date: any) {

    let dateComponents = date.split(' ');
    let datePieces = dateComponents[0].split("-");
    let timePieces = dateComponents[1].split(":");
    let dateFormat = new Date(datePieces[2],(datePieces[1]-1),datePieces[0],timePieces[0],
                              timePieces[1],timePieces[2]);
    let newDate;
    let dateBack = new Date( dateFormat.toString());
    let year = new Intl.DateTimeFormat('es', { year: 'numeric' }).format(dateBack);
    let month = new Intl.DateTimeFormat('es', { month: 'short' }).format(dateBack);
    let day = new Intl.DateTimeFormat('es', { day: '2-digit' }).format(dateBack);
    let hour = new Intl.DateTimeFormat('es', { hour: '2-digit', minute: '2-digit'}).format(dateBack);
    month = this.capitalizeFirstLetter(month);
    newDate = `${day} ${month}. ${year} - ${hour}`;
    return newDate;
  }

  public static capitalizeFirstLetter(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  public static downloadFile(data: string, fileName: string = 'file', fileType: string = 'text/plain'): void
  {
    const blob = new Blob([data], { type: fileType });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(url);
    link.remove();
  }

  public static launchCustomGrowlNotification(notification: SiigoNotificationGrowlAtom,
    title: string, message: string, type: TypeNotificationEnum,
    position: PositionNotificationEnum = PositionNotificationEnum.BottomRight,
    timeout: number = 8000): void
  {
    //* build custom growl notification
    const notificationErrorGrowl: INotification = {
      id: 0,
      title: title,
      text: message,
      type: type,
      position: position,
      timeout: timeout,
    };
    //* show custom growl notification
    notification.showNotification(notificationErrorGrowl);
  }
}
