import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DebtPayments } from '../models/debt-payment';
import { DocumentData } from '../models/documet-data.interface';
import { CommentsModal } from '../models/documet-data.interface';

@Injectable({
  providedIn: 'root',
})
export class SendMailService {
  private apiPublicDocumentView: any;

  public headers: any;

  constructor(private _httpClient: HttpClient) {
    this.apiPublicDocumentView =
      environment.publicDocumentViewEndpoint.includes('v2')
        ? environment.publicDocumentViewEndpointV2
        : environment.publicDocumentViewEndpoint;
    this.headers = new HttpHeaders().set('X-Siigo-token', environment.token);
  }

  sendMail(dataEncrypted: string, data: any) {
    return this._httpClient.post<any>(
      `${this.apiPublicDocumentView}send-mail?data=${dataEncrypted}`,
      data,
      { headers: this.headers }
    );
  }
}
