import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'invoice-view',
  templateUrl: './invoice-view.component.html',
  styleUrls: ['./invoice-view.component.css'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class InvoiceViewComponent implements OnInit {
  @Input() fromPOS: boolean;
  @Input() htmlInvoice: string;
  @ViewChild('container', { static: true }) container: ElementRef;

  constructor() {}

  ngOnInit(): void {
    this.loadDocument();
  }

  loadDocument() {
    this.container.nativeElement.innerHTML = this.fixStyles(this.htmlInvoice);
  }

  fixStyles(html: string) {
    html = html.replace(
      '<style type="text/css">',
      '<style type="text/css"> ' +
        '*,*::before,*::after {margin: 0;padding: 0;} ' +
        'html { -webkit-print-color-adjust: exact; }'
    );
    html = html.replace(`display: table;`, ``);
    html = html.replace(
      `"max-height: 92px;  max-width: 100%;"`,
      `"max-height: 92px;  max-width: 100%; margin-top: 15px;"`
    );
    return html;
  }
}
