import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Services
import {
    GoogleAnalyticsService
} from './service.index';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    GoogleAnalyticsService
  ],
  declarations: []
})
export class ServiceModule { }
