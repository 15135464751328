import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { SiigoDataTableMolecule } from "@siigo/siigo-data-table-molecule-angular";
import { environment } from "src/environments/environment";
import { DataAdapterService } from "src/app/services/commons/data-adapter.service";
import { InvoiceOptionComponent } from "../invoice-option/invoice-option.component"; 

@Component({
    selector: 'app-data-table',
    templateUrl: './data-table.component.html',
    styleUrls: ['./data-table.component.css']
})

export class DataTableComponent implements OnInit {

  @ViewChild('dataTable', { static: true }) dataTable: SiigoDataTableMolecule;
  @Input('defaultTemplate') defaultTemplate: object = {};

  public flags: any = {};
  private env: any;
  private dictionaryTotalInput = new Map();
  private dictionaryValidInput = new Map();

  constructor(private invoiceComponent: InvoiceOptionComponent,
    private translate: TranslateService,
    private _dataAdapterService: DataAdapterService) {
    this.env = environment;
  }

  ngOnInit(): void {
    this.setDefaultFlags();
  }

  setDefaultFlags() {
    this.flags.rowNumber = false;
    this.flags.selectRows = false;
    this.flags.reorder = false;
    this.flags.dynamic = false;
    this.flags.sticky = false;
    this.flags.sortable = false;

    this.dataTable.setDefaultTemplate({
      dueName: (value, args) => {
        const labelDueName = document.createElement('p')
        labelDueName.textContent = args.item.dueName;
        labelDueName.setAttribute("style", "color: #37465A");
        return labelDueName;
      },
      balance: (value, args) => {
        const labelBalance = document.createElement('p')
        labelBalance.textContent = args.item.balance;
        labelBalance.id = "p-" + args.item.id;
        labelBalance.setAttribute("style", "color: #37465A");
        return labelBalance;
      },
      actions: (value, args) => {
        let htmlElement = this.invoiceComponent.invoiceData.allowPartialPayments
          ? this.buildInputElement(args)
          : this.buildParagraphElement(args);

        return htmlElement;
      }
    });
  }

  onchangeInput(event, id, input){
    this.dataTable.data.filter(item => {
      if (id == item.id) {
        var balance = parseFloat(item.balance.replace("$ ", "").replaceAll(",", ""));
        var eventItem = parseFloat(event.detail === "" ? 0 : event.detail.replaceAll(",", ""));
        const { isPaymentValid, messageError } = this.validatePaymentAmount(eventItem, balance);
        input.setErrorInput(messageError);
        this.dictionaryValidInput.set(id, isPaymentValid);
        input.setValidator(() => isPaymentValid);
        if (!isNaN(eventItem) && eventItem > 0) {
          this.dictionaryTotalInput.set(id, eventItem);
        }else{
          this.dictionaryValidInput.delete(id);
          this.dictionaryTotalInput.delete(id);
        }
      }
      input.validate();
    });
  }

  validatePaymentAmount(paymentAmount: number, balance: number) {
    const isPaymentOutOfBalance = paymentAmount > balance;
    const isPaymentValid = !isPaymentOutOfBalance;
    let messageError: string | null = null;
    if(isPaymentOutOfBalance){
      this.translate.get("ExceedsBalanceValue").subscribe((message: string) => {
        messageError = message;
      });
    }

    return { isPaymentValid, messageError };
  }

  updateDefaultTemplate(data: any){
    this.dataTable.data = data;
  }

  updateData(data: object[]) {
    this.dataTable.data = data;
  }

  updateHeaders(headers: object[]) {
    this.dataTable.headers = headers;
  }

  setValueFirstInput(args) {
    let balance = this.invoiceComponent.invoiceData.moneySettings.getIsLocalMoney()
                  ? parseInt(args.item.balance.replace("$ ", "").replaceAll(",", ""))
                  : parseFloat(args.item.balance.replace("$ ", "").replaceAll(",", ""));
    this.dictionaryTotalInput.set(args.item.id, balance);
    this.invoiceComponent.calculateTotal(this.dictionaryTotalInput);
    return Number.isInteger(balance) ? balance.toString() : balance.toFixed(2).toString();
  }

  validateAllInputs(): boolean {
    let isValid = true;
    this.dictionaryValidInput.forEach(value => {
      if (!value){
        isValid = value
      }
    })
    return isValid;
  }

  setValidatorToPaymentInputs() {
    const shadowRoot = this.dataTable['el'].shadowRoot;
    if (shadowRoot != null) {
      const paymentsInputs = shadowRoot.querySelectorAll('siigo-input-atom');
      paymentsInputs.forEach((input) => {
        input.setValidator(() => false);
        input.validate();
      });
    }
  }

  buildParagraphElement(args): HTMLElement {
    const paragraph: HTMLParagraphElement = document.createElement('p');
    paragraph.id = "siigo-input-" + args.id;
    paragraph.style.marginTop = "5px";
    let valueDue: number = parseFloat(this.setValueFirstInput(args));
    paragraph.textContent = this._dataAdapterService
      .numberToDueTypeFormat(valueDue);

    return paragraph;
  }

  buildInputElement(args): HTMLElement {
    const input: HTMLSiigoInputAtomElement = document.createElement('siigo-input-atom');
    input.style.marginTop = "5px"
    input.required = false;
    input.type = this.invoiceComponent.invoiceData.moneySettings.getIsLocalMoney()
      ? "integer"
      : "decimal";
    input.countWhitDecimal = true;
    input.placeholder = "0";
    input.decimalDigit = this.env.decimalDigit;
    input.decimalSeparator = this.env.decimalSeparator;
    input.prefixText = "fas fa-dollar-sign";
    input.prefixType = "icon";
    input.id = "siigo-input-" + args.id;

    if (this.dataTable.data.length > 0 && args.id == 0) {
      input.value = this.setValueFirstInput(args);
      input.validate();
    }

    input.addEventListener('change', (event: any) => {
      this.onchangeInput(event, args.item.id, input);
      this.invoiceComponent.calculateTotal(this.dictionaryTotalInput);
    });

    return input;
  }
}
