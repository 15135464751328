import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { assetUrl } from 'src/single-spa/asset-url';
import { SiigoTranslation } from '@siigo/siigo-typescript-localization-translation';


@Injectable({
  providedIn: 'root',
})
export class TranslationService implements TranslateLoader {
  constructor() {}
  getTranslation(lang: string) {
    const language = lang || environment.defaultLanguage;
    return new Observable((subscriber) => {
      this.getLanguageDefinitions(language)
        .then((definitions) => {
          subscriber.next(definitions);
        })
        .catch((error) => {
          console.error('Error adding new language: ', error);
          subscriber.error(error);
        });
    });
  }
  private async getLanguageDefinitions(lang: string): Promise<any> {
    const pathI18n = `i18n/${lang}.json`;
    const apiAddress = assetUrl(pathI18n)
    const languageServiceInstance =
      await SiigoTranslation.createLanguageServiceInstance(
        lang,
        apiAddress,
        false
      );
    const languageDefinitionsObject = SiigoTranslation.parseLanguageDefinitions(
      languageServiceInstance.languageDefinitions
    );
    return languageDefinitionsObject;
  }
}
