import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CountryISOEnum } from '@siigo/siigo-library-enums';

@Component({
  selector: 'contact-with-us',
  templateUrl: './contact-with-us.component.html',
  styleUrls: ['./contact-with-us.component.css']
})
export class ContactWithUsComponent implements OnInit {

  @Input() isSaleNote: boolean;

  env: String = "";
  public isCol: boolean;
  public isEnableNewLabel: boolean;
  showcontactwithus: boolean = true;

  constructor() {
    this.env = environment.contactwithus;
    environment.countryCodeISO;
    this.isCol = false;
    this.isEnableNewLabel = false;
  }

  ngOnInit(): void {
    this.isCol = environment.countryCodeISO.toString() === CountryISOEnum.Colombia.toString();
    this.isEnableNewLabel = environment.isEnableNewLabel.toString() === 'true';
  }
}
