import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PrintService {
  constructor() {}

  public Print(html: string) {
    html = this.removeSticker(html);
    const styles = this.getStylesPrint(html);
    const wprint = window.open(window.location.href);
    wprint!.document.write(styles);
    wprint!.document.write(html);
    wprint!.onload = () => {
      wprint!.focus();
      wprint!.print();
      wprint!.close();
    };
    wprint!.document.close();
  }

  public removeSticker(html: string): string {
    // * FV
    html = html.replace(
      'invoceERP_ribbon_accept.png',
      'invoceERP_ribbon_empty.png'
    );
    html = html.replace(
      'invoceERP_ribbon_PartialPaid.png',
      'invoceERP_ribbon_empty.png'
    );
    html = html.replace(
      'invoceERP_ribbon_Read.png',
      'invoceERP_ribbon_empty.png'
    );
    html = html.replace(
      'invoceERP_ribbon_Removed.png',
      'invoceERP_ribbon_empty.png'
    );
    html = html.replace(
      'invoceERP_ribbon_CreditNote.png',
      'invoceERP_ribbon_empty.png'
    );
    html = html.replace(
      'invoceERP_ribbon_CreditNotePartial.png',
      'invoceERP_ribbon_empty.png'
    );
    html = html.replace(
      '<img src="https://qastaging.siigo.com//empresaApiV1//Img//invoceERP_ribbon_Paid.png" width="52" height="91">',
      ''
    );

    // * FC
    html = html.replace(
      'purchaseERP_ribbon_Paid.png',
      'purchaseERP_ribbon_empty.png'
    );
    html = html.replace(
      'purchaseERP_ribbon_PartialPaid.png',
      'purchaseERP_ribbon_empty.png'
    );
    html = html.replace(
      'purchaseERP_ribbon_DebitNote.png',
      'purchaseERP_ribbon_empty.png'
    );
    html = html.replace(
      'purchaseERP_ribbon_DebitNotePartial.png',
      'purchaseERP_ribbon_empty.png'
    );

    // * Quote
    html = html.replace(
      'erpquote_ribbon_approved.png',
      'erpquote_ribbon_empty.png'
    );
    html = html.replace(
      'erpquote_ribbon_invoiced.png',
      'erpquote_ribbon_empty.png'
    );
    html = html.replace(
      'erpquote_ribbon_dicarded.png',
      'erpquote_ribbon_empty.png'
    );
    html = html.replace('quote_ribbon_approved.png', 'quote_ribbon_empty.png');
    html = html.replace('quote_ribbon_dicarded.png', 'quote_ribbon_empty.png');

    // * OC
    html = html.replace(
      'purchaseorder_ribbon_partialpurchase.png',
      'default_ribbon_empty.png'
    );
    html = html.replace(
      'purchaseorder_ribbon_totalpurchase.png',
      'default_ribbon_empty.png'
    );

    // * RM
    html = html.replace(
      'remission_ribbon_partiallycrossed.png',
      'default_ribbon_empty.png'
    );
    html = html.replace(
      'remission_ribbon_totallycrossed.png',
      'default_ribbon_empty.png'
    );
    html = html.replace(
      'default_ribbon_annulled.png',
      'default_ribbon_empty.png'
    );

    return html;
  }

  public getStylesPrint(html: string): string {
    let styles = '<style type="text/css"> @media print { ';
    styles += '     .invoice-container { height: initial !important; } ';
    styles += '     .invoice-box { height: inherit !important; } ';
    styles += '     .Numeric { width: inherit; } ';
    styles +=
      '     .invoice-container-left { padding-right: 10px !important; } ';
    styles +=
      '     .invoice-container-right { padding-right: 20px !important; } ';
    styles += '     .TotalContent { display: inline-table; } ';
    styles += '     .TableDocumentDetail { bottom: 0px !important; } ';
    styles += '     .UserDetails { min-width: 150px; } ';

    if (html.includes('ribbon_empty.png')) {
      styles += '     .container-invoice>table>tbody>tr>td>div>img ';
      styles +=
        '     .invoice-container-left>table>tbody>tr>td>div>img { opacity: 0;  } ';
      styles +=
        '     .invoice-container-left>table>tbody>tr>td>div>img[alt="QrCode"] { opacity: 1 !important;  } ';
      styles += '     .UserLogo>img { opacity: 1 !important; } ';
    }

    styles += ' } </style > ';

    return styles;
  }
}
