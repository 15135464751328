import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ErrorData } from 'src/app/models/error-data';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-error-view-page',
  templateUrl: './error-view-page.component.html',
  styleUrls: ['./error-view-page.component.css']
})
export class ErrorViewPageComponent implements OnInit {
  public urlUnderConstruction: string = '';
  public titleErrorInvoice: string = '';
  public descriptionErrorInvoice: string = '';

  @Input() errorData: ErrorData = {
    title: this.translate.instant('TitleErrorUploadInvoice'),
    description: this.translate.instant('DescriptionErrorUploadInvoice')
  };

  constructor(private readonly translate: TranslateService) {}

  ngOnInit(): void {
    this.urlUnderConstruction = `${environment.pathAssets}images/under_construction.png`;
  }

}
