import { Component, Input, OnInit } from '@angular/core';
import { CountryISOEnum } from '@siigo/siigo-library-enums';
import { Created } from 'src/app/models/documet-data.interface';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'billing-contact',
  templateUrl: './billing-contact.component.html',
  styleUrls: ['./billing-contact.component.css']
})
export class BillingContactComponent implements OnInit {
  @Input() metadata: Created;
  @Input() isSaleNote: boolean;
  public isCol: boolean;
  public isEnableNewLabel: boolean;  

  constructor() {
    this.metadata = {
      fullName: "Nombre Usuario",
      userName: 'email@correo.com'
    }
    this.isCol = false;
    this.isEnableNewLabel = false;
  }

  ngOnInit(): void {
    this.isCol = environment.countryCodeISO.toString() === CountryISOEnum.Colombia.toString();
    this.isEnableNewLabel = environment.isEnableNewLabel.toString() === 'true';
  }
}
