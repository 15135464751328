import { Injectable } from '@angular/core';
import { AccountBalanceModel } from 'src/app/models/account-balance';
import { BackUrls } from 'src/app/models/back-url';
import { Contact, InvoiceData } from 'src/app/models/documet-data.interface';
import { DuesTableModel } from 'src/app/models/dues-table';
import { MoneySettings } from 'src/app/models/money-settings';
import { Utils } from 'src/app/utils/utils';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DataAdapterService {
  private env: any;
  constructor() {
    this.env = environment;
  }

  duesTypeToDataTableAdapt(duesData: AccountBalanceModel[]): DuesTableModel[] {
    let adaptData: DuesTableModel[] = [];
    for (let i = 0; i < duesData.length; i++) {
      let duesType = {
        id: duesData[i].acDueBalanceID,
        dueName: duesData[i].dueName,
        dueQuote: duesData[i].dueQuote,
        balance: this.numberToDueTypeFormat(duesData[i].balance),
      };
      adaptData.push(duesType);
    }
    return adaptData;
  }

  buildDuesTypeFromInvoiceData(
    invoiceData: InvoiceData
  ): AccountBalanceModel[] {
    return [
      {
        acDueBalanceID: 1,
        dueQuote: 1,
        dueName: `${invoiceData.docClass}-${invoiceData.docCode}-${invoiceData.docNumber}`,
        balance: invoiceData.moneySettings.totalValue,
        moneyCode: invoiceData.moneySettings.companyMoney,
        duePrefix: `${invoiceData.docClass}-${invoiceData.docCode}`,
        dueConsecutive: invoiceData.docNumber,
      },
    ];
  }

  duesTypeToDuesDetailDataTableAdapt(duesData) {
    let adaptData: any[] = [];
    for (let i = 0; i < duesData.length; i++) {
      let duesType = {
        dueName: duesData[i].dueName,
        dueQuote: duesData[i].dueQuote,
        balance: this.numberToDueTypeFormat(duesData[i].balance),
        dueValue: this.numberToDueTypeFormat(duesData[i].dueValue),
        valueToDue: this.numberToDueTypeFormat(duesData[i].valueToDue),
        dueDays: duesData[i].dueDays,
      };
      adaptData.push(duesType);
    }
    return adaptData;
  }

  debtsPaymentsDataTableAdapt(debtPaymentsData) {
    let adaptData: any[] = [];
    debtPaymentsData.debtPayments.forEach((debtPayment) => {
      adaptData.push({
        docName: debtPayment.docName,
        value: this.numberToDueTypeFormat(debtPayment.value),
        docDate: Utils.customDate(debtPayment.docDate),
      });
    });

    return adaptData;
  }

  numberToDueTypeFormat(dueData, moneyCode?: string) {
    return `${this.env.currencyCharacterBalance} ${dueData.toLocaleString(
      moneyCode === 'COP' ? 'es-ES' : this.env.currencyLanguageFormat,
      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }
    )}`;
  }

  providerPreferenceDataAdapt(
    duesTypeInfo: any,
    dictionaryInput: any,
    contact: Contact,
    invoiceData: InvoiceData,
    moneySettings: MoneySettings
  ) {
    var providerPreference;
    var paymentItemsList: any[] = [];
    for (let [key, value] of dictionaryInput) {
      const due: AccountBalanceModel = duesTypeInfo.find(
        (obj: AccountBalanceModel) => {
          return obj.acDueBalanceID === key;
        }
      );

      if (due !== undefined) {
        let paymentItem = {
          title: `${due.duePrefix}-${due.dueConsecutive}`,
          description: due.dueName,
          quantity: this.env.defaultQuantityMercadoPago,
          currencyId: due.moneyCode,
          unitPrice: moneySettings.getIsLocalMoney()
            ? value
            : moneySettings.calculateTotalWithExchangeValue(value),
          realUnitPrice: moneySettings.getIsLocalMoney()
            ? due.balance
            : moneySettings.calculateTotalWithExchangeValueWithoutFloor(
                due.balance
              ),
          foreignCurrencyId: moneySettings.getIsLocalMoney()
            ? null
            : moneySettings.invoiceMoney,
          foreignUnitPrice: moneySettings.getIsLocalMoney() ? 0 : value,
          exchangeRate: moneySettings.exchangeRate,
        };
        paymentItemsList.push(paymentItem);
      }
    }
    // Valores quemados temporalmente
    return (providerPreference = {
      providerName: 'MercadoPago',
      paymentItems: paymentItemsList,
      internalUserId: invoiceData.userCode,
      payer: {
        name: contact.firstName,
        surName: contact.lastName,
        email: contact.email,
      },
      backUrls: this.generateBackUrlObject(),
      customer: {
        accountId: invoiceData.accountCode,
        accountGuid: invoiceData.accountGuid,
        identification: invoiceData.identification,
        branchOffice: invoiceData.branchOffice,
        accountFullName: invoiceData.accountFullName,
      },
    });
  }

  private generateBackUrlObject() {
    let currentUrl = window.location.href;
    let backUrls: BackUrls;
    backUrls = {
      success: `${currentUrl}&state=Success`,
      failure: `${currentUrl}&state=Failure`,
      pending: `${currentUrl}&state=Pending`,
    };

    return backUrls;
  }
}
