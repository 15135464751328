import { environment } from "src/environments/environment";

export class MoneySettings {
  private isLocalMoney: boolean = true;
  private isConversionNeeded: boolean = false;
  private isDueGreaterThanBalanceValue: boolean = false;
  private readonly minimumPaymentAmountLocal: number;

  constructor(
    public exchangeRate: number,
    public minimumInvoiceBalance: number,
    public companyMoney: string,
    public invoiceMoney?: string | undefined,
    public dueAction?: number,
    public readonly totalValue: number = 0,
  ){
    this.moneyValidations(minimumInvoiceBalance, companyMoney, invoiceMoney, dueAction);
    this.minimumPaymentAmountLocal = environment.minimumInvoiceBalance;
  }

  private moneyValidations(
    minimumInvoiceBalance: number,
    companyMoney: string,
    invoiceMoney?: string | undefined,
    dueAction?: number
  ): void {
    // * validate if loadData endpoint returns a minimum invoice balance, otherwise we need to set the default
    if(!minimumInvoiceBalance) {
      this.minimumInvoiceBalance = environment.minimumInvoiceBalance;
      this.isConversionNeeded = true;
    }
    // * validate if the money is local or foreign
    this.isLocalMoney = invoiceMoney != undefined && invoiceMoney == companyMoney;
    // * validate if the dues is greater than the minimum balance value
    this.isDueGreaterThanBalanceValue = this.duesIsGreaterThanMinimumBalanceValidation(dueAction);
  }

  public duesIsGreaterThanMinimumBalanceValidation(dueAction?: number) : boolean {
    if(this.isConversionNeeded == false){
      return dueAction != undefined &&
        dueAction != null &&
        dueAction >= this.minimumInvoiceBalance;
    } else {
      return dueAction != undefined &&
        dueAction != null &&
        (dueAction * this.exchangeRate) >= this.minimumInvoiceBalance;
    }
  }

  public getIsLocalMoney(): boolean {
    return this.isLocalMoney;
  }

  public isDueIsGreaterThanBalance(): boolean {
    return this.isDueGreaterThanBalanceValue;
  }

  public getHeaderMoneyCode() : string {
    return this.isLocalMoney
      ? ''
      : ` (${this.invoiceMoney})`;
  }

  public calculateTotalWithExchangeValue(originTotal: number) : number {
    let localValue: number = originTotal * this.exchangeRate;
    let localValueRounded = Math.floor(localValue);
    return localValueRounded;
  }

  public calculateTotalWithExchangeValueWithoutFloor(originTotal: number) : number {
    let localValue: number = originTotal * this.exchangeRate;
    return localValue;
  }

  public getMinimumInvoiceBalance() : number {
    if (this.isConversionNeeded) {
      return parseFloat((this.minimumInvoiceBalance / this.exchangeRate).toFixed(2));
    }
    return this.minimumInvoiceBalance;
  }

  public getMinimumPaymentAmountLocal() : number {
    return this.minimumPaymentAmountLocal;
  }
}
