import { Component } from '@angular/core';
import { Event, ResolveEnd, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Siigo.Frontend.PublicViews';
  constructor(private router: Router){
    this.router.events.pipe(filter((e: Event): e is RouterEvent => e instanceof ResolveEnd)).subscribe((e: RouterEvent) => {
      console.log(e.url)
    })
  }
}
