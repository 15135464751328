import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DocumentViewManagerService } from 'src/app/managers/document-view-manager.service';
import { ErrorData } from 'src/app/models/error-data';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'payments-configuration',
  styleUrls: ['./payments-configuration.component.css'],
  templateUrl: './payments-configuration.component.html'
})

export class PaymentsConfigurationComponent implements OnInit {

  public urlPaymentsConfiguration: string = "";
  public showErrorPage: boolean = false;
  public code: string = "";
  public state: string = "";
  errorData: ErrorData;
  constructor(private _documentViewManagerService: DocumentViewManagerService,
    private _route: ActivatedRoute,
    private _translate: TranslateService) {

  }

  ngOnInit(): void {
    this.getQueryParams();
    this.redirectToUrlPaymentsConfiguration(this.state);
  }

  async redirectToUrlPaymentsConfiguration(companyKey: string) {
    try {
      if (!this.code || !this.state) {
        this._translate.get(['MercadoPagoLinkRejectedTitle', 'CloseCurrentPageMessage'])
        .subscribe((res: any) => {
          this.errorData = {
            title: res.MercadoPagoLinkRejectedTitle,
            description: res.CloseCurrentPageMessage
          };

          this.showErrorPage = true;
        });
        return;
      }
      this.urlPaymentsConfiguration = await this._documentViewManagerService.getUrlPaymentsConfiguration(companyKey);
      window.location.href = `${this.urlPaymentsConfiguration}?code=${this.code}`;
    } catch (error) {
      console.error(error);
      this.showErrorPage = true;
    }
  }

  private getQueryParams() {
    this.code = this._documentViewManagerService.getEncryptedData(
      this._route,
      "code",
    );
    this.state = this._documentViewManagerService.getEncryptedData(
      this._route,
      "state",
    );
  }

}
