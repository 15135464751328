import { Pipe, PipeTransform } from "@angular/core";
import { environment } from "src/environments/environment";

@Pipe({name: 'FormatValues'})
export class FormatValues implements PipeTransform {
  transform(value: number) : string {
    let strValue = '';
    if(!isNaN(value) && value != null && value != undefined){
      strValue = `${environment.currencyCharacterBalance} ${value.toLocaleString(
        environment.currencyLanguageFormat, { 
          minimumFractionDigits: 2 
        }
      )}`;
    }
    return strValue;
  }
}