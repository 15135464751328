import { Component, Input, OnInit } from '@angular/core';
import { Attachment } from 'src/app/models/documet-data.interface';

@Component({
  selector: 'attachments-view',
  templateUrl: './attachments-view.component.html',
  styleUrls: ['./attachments-view.component.css']
})
export class AttachmentsViewComponent implements OnInit {

  @Input() attachments: Attachment[];

  constructor() { }

  ngOnInit() {

  }
}