import { MoneySettings } from './money-settings';

export interface Historical {
  description?: string;
  date?: string;
}

export interface Attachment {
  name: string;
  guid: string;
  downloadURL: string;
}

export interface Balance {
  totalValue?: number;
  dueAction?: number;
  dueValue?: number;
  valueToDue?: number;
  moneyCode?: string;
}

export interface Company {
  moneyCode: string;
  companyFullName: string;
}

export interface Created {
  userId?: number;
  fullName: string;
  userName: string;
  date?: Date;
}

export interface Updated {
  userId: number;
  fullName: string;
  userName: string;
  date: Date;
}

export interface Metadata {
  created: Created;
  updated: Updated;
}

export interface PaymentTypes {
  acPaymentMeanId: number;
  code: string;
  name: string;
  acAccountCode: number;
  type: number;
  inUse: boolean;
  dueType: number;
}

export interface DocumentData {
  document: string;
  fromPOS: boolean;
  historical: Historical[];
  attachments: Attachment[];
  docClass: string;
  docCode: string;
  docNumber: number;
  balance: Balance;
  company: Company;
  metadata: Metadata;
  account: Account;
  accountUrl: string;
  enabledPaymentOnline: boolean;
  allowPartialPayments?: boolean;
  allowXMLDownLoad?: boolean;
  planWithoutAccountingSettings: boolean;
  exchangeRate: number;
  minimumInvoiceBalance: number;
  isNonElectronicInvoice?: boolean;
  paymentTypes: PaymentTypes[];
  aditionalInfo: {
    logoBase64: string;
    principalContactPhone: string;
    dueDate: string;
  };
}

export interface Account {
  accountCode: number;
  accountGuid: string;
  firstName: string;
  lastName: string;
  fullName: string;
  companyName: string;
  identification: string;
  branchOffice: string;
  principalContactCode: number;
  contacts: Contact[];
}

export interface Contact {
  contactCode: number;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  isPrincipal: boolean;
}

export interface InvoiceData {
  docClass: string;
  docCode: string;
  docNumber: number;
  accountCode: number;
  contacts: any[];
  accountGuid: string;
  accountFullName: string;
  accountUrl: string;
  identification: string;
  branchOffice: string;
  dueAction: number | undefined;
  invoiceMoneyCode: string | undefined;
  enabledPaymentOnline: boolean;
  allowPartialPayments: boolean;
  allowXMLDownLoad: boolean;
  planWithoutAccountingSettings: boolean;
  companyMoneyCode: string;
  userCode?: string;
  moneySettings: MoneySettings;
  companyFullName: string;
  isNonElectronicInvoice: boolean;
  paymentsData: PaymentData[];
  balance: Balance;
  aditionalInfo: {
    logoBase64: string;
    principalContactPhone: string;
    dueDate: string;
  };
}

export interface PaymentData {
  aCPaymentMeanId: number;
  dueType: number;
  inUse: boolean;
}

export interface CommentsModal {
  commentDate: string;
  authorType: string;
  authorCode: string;
  author: string;
  comment: string;
}

export class DataCommentsInvoice {
  constructor(
    public user: string,
    public voucherType: string,
    public date: string,
    public comment: string
  ) {}
}

export interface DateLimit {
  createdDate: string;
}
