import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DebtPayments } from '../models/debt-payment';
import { DocumentData } from '../models/documet-data.interface';
import { CommentsModal } from '../models/documet-data.interface';
import { AccountBalanceModel } from '../models/account-balance';
import { FilesManagement } from '../models/files-management';

@Injectable({
  providedIn: 'root',
})
export class DocumentViewManagerService {
  private apiPublicDocumentView: any;
  private apiPublicDocumentViewV2: any;
  public headers: any;
  public invoiceData: DocumentData;
  public debtPayments: DebtPayments;
  public commentsModal: CommentsModal;

  constructor(private _httpClient: HttpClient) {
    this.apiPublicDocumentView = environment.publicDocumentViewEndpoint;
    this.apiPublicDocumentViewV2 = environment.publicDocumentViewEndpointV2;
    this.headers = new HttpHeaders().set('X-Siigo-token', environment.token);
  }

  async loadData(
    encryptedData: string,
    extraFields: string,
    isColombia: boolean = false
  ): Promise<DocumentData> {
    const apiEndpoint = isColombia
      ? this.apiPublicDocumentViewV2
      : this.apiPublicDocumentView;
    this.invoiceData = await this._httpClient
      .get<DocumentData>(
        `${apiEndpoint}load-data?data=${encryptedData}&extraFields=${extraFields}`,
        { headers: this.headers }
      )
      .toPromise();

    return this.invoiceData;
  }

  async downloadXmlAsync(encryptedData: string): Promise<FilesManagement> {
    const xmlbase64Data: FilesManagement = await this._httpClient
      .get<FilesManagement>(
        `${this.apiPublicDocumentView}get-xml?data=${encryptedData}`,
        { headers: this.headers }
      )
      .toPromise();

    return xmlbase64Data;
  }

  createPreference(encryptedData: string, payload: any) {
    return this._httpClient
      .post<any>(
        `${this.apiPublicDocumentView}provider-preference?data=${encryptedData}`,
        payload,
        { headers: this.headers }
      )
      .toPromise();
  }

  getEncryptedData(route: ActivatedRoute, queryString: string) {
    let encryptedData = '';
    route.queryParams.subscribe((res) => {
      if (!res[queryString]) {
        console.log('Ha ocurrido un error al cargar la data');
      } else {
        encryptedData = encodeURIComponent(res[queryString]);
      }
    });
    return encryptedData;
  }

  getPayments(encryptedData: string): Promise<DebtPayments> {
    let apiURI = `${this.apiPublicDocumentView}payments?data=${encryptedData}`;
    return this._httpClient
      .get<DebtPayments>(apiURI, { headers: this.headers })
      .toPromise();
  }

  async getComments(encryptedData: string): Promise<CommentsModal[]> {
    const result = await this._httpClient
      .get<any>(`${this.apiPublicDocumentView}comments?data=${encryptedData}`, {
        headers: this.headers,
      })
      .toPromise();

    return result.comments;
  }

  getDues(
    encryptedData: string,
    dueName: string,
    dueConsecutive: string,
    accountCode: string
  ): Promise<AccountBalanceModel[]> {
    return this._httpClient
      .get<AccountBalanceModel[]>(
        `${this.apiPublicDocumentView}dues?data=${encryptedData}&dueName=${dueName}&dueConsecutive=${dueConsecutive}&accountCode=${accountCode}`,
        { headers: this.headers }
      )
      .toPromise();
  }

  createComments(encryptedData: string, comments: any) {
    return this._httpClient
      .post<any>(
        `${this.apiPublicDocumentView}comments?data=${encryptedData}`,
        comments,
        { headers: this.headers }
      )
      .toPromise();
  }

  async getUrlPaymentsConfiguration(companyKey: string) {
    const urlService = `${this.apiPublicDocumentView}payments-configuration-path?companyKey=${companyKey}`;

    const result = await this._httpClient
      .get(urlService, { headers: this.headers, responseType: 'text' })
      .toPromise();

    return result;
  }
}
