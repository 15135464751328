import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DocumentViewManagerService } from 'src/app/managers/document-view-manager.service';
import { DataAdapterService } from 'src/app/services/commons/data-adapter.service';
import { PrintService } from 'src/app/services/print/print.service';
import { DataTableComponent } from '../data-table/data-table.component';
import { SiigoDialogModalMolecule } from '@siigo/siigo-dialog-modal-molecule-angular';
import { ActivatedRoute } from '@angular/router';
import {
  DataCommentsInvoice,
  InvoiceData,
  PaymentData,
} from 'src/app/models/documet-data.interface';
import { SiigoModalCommentsMolecule } from '@siigo/siigo-modal-comments-molecule-angular';
import { Utils } from 'src/app/utils/utils';
import { SiigoPanelModalMolecule } from '@siigo/siigo-panel-modal-molecule-angular';
import { SiigoDataTableMolecule } from '@siigo/siigo-data-table-molecule-angular';
import {
  HeaderDataTable,
  HeaderDataTableDebtPayments,
} from '../../../assets/data/data-table/headerDataTable.json';
import { DebtPaymentDto } from 'src/app/models/debt-payment';
import { SendMailService } from 'src/app/services/send-mail.service';
import { SiigoButtonDropdownAtom } from '@siigo/siigo-button-dropdown-atom-angular';
import { InvoiceViewComponent } from '../invoice-view/invoice-view.component';
import { SiigoButtonAtom } from '@siigo/siigo-button-atom-angular';
import { SiigoNotificationGrowlAtom } from '@siigo/siigo-notification-growl-atom-angular';
import { AccountBalanceModel } from 'src/app/models/account-balance';
import { DuesTableModel } from 'src/app/models/dues-table';
import { SiigoNotificationsInlineMolecule } from '@siigo/siigo-notifications-inline-molecule-angular';
import { environment } from 'src/environments/environment';
import {
  CountryISOEnum,
  TypeNotificationEnum,
} from '@siigo/siigo-library-enums';
import {
  FileManagement,
  FilesManagement,
} from 'src/app/models/files-management';
import { ElectronicInvoiceStatus } from 'src/app/enums/electronic-invoice-status';

@Component({
  selector: 'invoice-option',
  templateUrl: './invoice-option.component.html',
  styleUrls: ['./invoice-option.component.css'],
})
export class InvoiceOptionComponent implements OnInit {
  @ViewChild('popUpDataTable', { static: false })
  popUpDataTable: DataTableComponent;
  @ViewChild('modalDialogClaim') modalDialogClaim: SiigoDialogModalMolecule;
  @ViewChild('modalDialogError') modalDialogError: SiigoDialogModalMolecule;
  @ViewChild('modalComments', { static: false })
  modalComments: SiigoModalCommentsMolecule;
  @ViewChild('moreButtonDropdown') moreButtonDropdown: SiigoButtonDropdownAtom;
  @ViewChild('duesDetailPanelModal', { static: false })
  duesDetailPanelModal: SiigoPanelModalMolecule;
  @ViewChild('modalDebtPayments', { static: false })
  modalDebtPayments: SiigoPanelModalMolecule;
  @ViewChild('duesDetailDataTable', { static: false })
  duesDetailDataTable: SiigoDataTableMolecule;
  @ViewChild('paymentDataTable', { static: false })
  paymentDataTable: SiigoDataTableMolecule;
  @ViewChild('modalSendMail', { static: false })
  modalSendMail: SiigoPanelModalMolecule;
  @ViewChild('payOnlineButton', { static: false })
  payOnlineButton: SiigoButtonAtom;
  @ViewChild('showNotificationError', { static: false })
  showNotificationError: SiigoNotificationGrowlAtom;
  @ViewChild('siigoNotificationInlineValidation', { static: false })
  siigoNotificationInlineValidation: SiigoNotificationsInlineMolecule;

  @Input() invoiceView: InvoiceViewComponent;

  invoiceHtml: any = '';
  @Input() invoiceData: InvoiceData;
  @Input() contact: any;
  @Input() isSaleNote: boolean;
  dueName: string;
  dueConsecutive: string;
  accountCode: string;
  emailMessage: string = '';
  contactsEmails: any = [];
  modalDialogErrorContent: string = '';
  selectContact: any;
  mailCC: string = '';

  public isPreviewModalOpen = false;
  public dataComments: any;
  public moreButtonOptions: any;
  public showPayOnlineButton: boolean;
  public userCommentaryInfo: any;
  public loading: boolean = false;
  public loadingDownloadFile: boolean = false;
  public isLoadingData: boolean = false;
  public notificationStatus: boolean = false;
  public notificationMessage: string;

  public buttonDropdownFunctions: { [K: string]: Function } = {
    accountStatus: () => this.redirectToAccountStatement(),
    dueDetail: () => this.openDuesDetailModal(),
    payments: () => this.openModalDebtPayments(),
  };

  //DataTable
  public headers;
  public headers_names: string[] = [];
  public data: DuesTableModel[] = [];
  public defaultTemplate;
  public duesTypeInfo: AccountBalanceModel[] = [];
  private encryptedData = '';
  public total: any;
  public totalInLocalMoney: number = 0;
  private dictionaryInput;

  //DuesDataTable
  public duesDetailTableData: any[] = [];
  public duesDetailDataTableHeaders = HeaderDataTable;

  //DataPayments
  public listDebtPaymentsData: DebtPaymentDto[] = [];
  public headerTableDebtPayments = HeaderDataTableDebtPayments;

  //text strings for translation
  public accountStatusText: string = '';
  public detailsOfQuotasText: string = '';
  public paymentsText: string = '';

  public isMexico: boolean = false;
  // this means that the payement type has an expiration date
  paymentTypeWithDue: number = 1;

  constructor(
    private _documentViewManagerService: DocumentViewManagerService,
    public printService: PrintService,
    public translate: TranslateService,
    private dataAdapterService: DataAdapterService,
    private _route: ActivatedRoute,
    private cdref: ChangeDetectorRef,
    private _sendmail: SendMailService
  ) {
    this.isMexico =
      Number(environment.countryCodeISO) === CountryISOEnum.Mexico;
    this.encryptedData = this._documentViewManagerService.getEncryptedData(
      this._route,
      'data'
    );
  }

  ngOnInit(): void {
    this.invoiceHtml = this.invoiceView.container.nativeElement;
    this.getTranslatedTextsStartup();
    this.loadDocumentInfo();
    this.loadMoreButtonOptions();
    this.loadPayOnlineButton();
    this.loadUserCommentaryInfo();
  }

  private validateActivePayOnlineButtonNotDuePayment(): boolean {
    if (this.invoiceData.paymentsData.length > 0) {
      const showButton = this.invoiceData.moneySettings.totalValue >= 1000;
      return this.invoiceData.enabledPaymentOnline && showButton;
    }

    return this.invoiceData.enabledPaymentOnline;
  }

  private validateActivePayOnlineButtonDuePayment(): boolean {
    if (this.invoiceData.enabledPaymentOnline === false) {
      return false;
    }
    const invoiceBalanceValid =
      this.invoiceData.dueAction != undefined &&
      this.invoiceData.moneySettings.isDueIsGreaterThanBalance();
    const isPlanWithoutAccountingSettings =
      this.invoiceData.planWithoutAccountingSettings &&
      this.invoiceData.moneySettings.duesIsGreaterThanMinimumBalanceValidation(
        this.invoiceData.moneySettings.totalValue
      );

    return invoiceBalanceValid || isPlanWithoutAccountingSettings;
  }

  public loadUserCommentaryInfo() {
    this.userCommentaryInfo = {
      user: this.contact.fullName,
      voucherType: `(${this.invoiceData.docClass}-${this.invoiceData.docCode}-${this.invoiceData.docNumber})`,
    };
  }

  public printInvoice() {
    this.printService.Print(this.invoiceHtml.innerHTML);
  }

  loadDocumentInfo() {
    this.dueName = `${this.invoiceData.docClass}-${this.invoiceData.docCode}`;
    this.dueConsecutive = `${this.invoiceData.docNumber}`;
    this.accountCode = `${this.invoiceData.accountCode}`;
  }

  getTranslatedTextsStartup() {
    this.translate
      .get([
        'AccountStatusButtonOption',
        'DueDetailsButtonOption',
        'PaymentsButtonOption',
      ])
      .subscribe((translations) => {
        (this.accountStatusText = translations.AccountStatusButtonOption),
          (this.detailsOfQuotasText = translations.DueDetailsButtonOption),
          (this.paymentsText = translations.PaymentsButtonOption);
      });
  }

  loadMoreButtonOptions() {
    this.moreButtonOptions = [
      {
        label: this.accountStatusText,
        value: 'accountStatus',
        selected: false,
      },
      {
        label: this.detailsOfQuotasText,
        value: 'dueDetail',
        selected: false,
      },
      {
        label: this.paymentsText,
        value: 'payments',
        selected: false,
      },
    ];
  }

  async loadPayOnlineButton() {
    // if the invoice has a payment with expiration date, we need to validate the balance of the document
    var isDuePayment = await this.hasPaymentWithDue();

    const showPayment = isDuePayment
      ? await this.validateActivePayOnlineButtonDuePayment()
      : await this.validateActivePayOnlineButtonNotDuePayment();
    this.showPayOnlineButton = showPayment;
    if (showPayment) {
      this._route.queryParams.subscribe((params) => {
        const miParam = params['showModalPayment'];
        if (miParam == 'true') {
          setTimeout(() => {
            this.openModalPayment();
          }, 1000);
        }
      });
    }
  }

  async loadDataPayments() {
    try {
      const debPayments = await this._documentViewManagerService.getPayments(
        this.encryptedData
      );
      this.listDebtPaymentsData =
        this.dataAdapterService.debtsPaymentsDataTableAdapt(debPayments);
      this.paymentDataTable.data = this.listDebtPaymentsData;
    } catch (error) {
      console.log(error);
    }
  }

  public selectButtonDropdownOption(e): void {
    this.buttonDropdownFunctions[e.detail[0].value]();
  }

  public async openModalPayment() {
    this.isPreviewModalOpen = true;
    this.isLoadingData = true;
    this.notificationStatus = false;
    this.loadHeaders();
    await this.loadData();
    this.total = '0.00';
    this.dictionaryInput = new Map();
    this.isLoadingData = false;
  }

  async openDuesDetailModal() {
    this.isLoadingData = true;
    this.duesDetailPanelModal.open();
    await this.loadDataDues();
    this.isLoadingData = false;
  }

  async openModalDebtPayments() {
    this.isLoadingData = true;
    this.modalDebtPayments.open();
    await this.loadDataPayments();
    this.isLoadingData = false;
  }

  async openModalSendMail() {
    this.contactsEmails = this.invoiceData.contacts.map(
      (contact: any, index: number) => {
        return {
          id: index,
          label: `${contact.firstName} (${contact.email})`,
          value: contact.contactCode.toString(),
          selected: index == 0 ? true : false,
        };
      }
    );
    this.selectContact = parseInt(this.contactsEmails[0].value);
    this.modalSendMail.open();
  }

  async sendMail() {
    this._sendmail
      .sendMail(this.encryptedData, {
        contactCode: this.selectContact,
        message: this.emailMessage,
        accountGuid: this.invoiceData.accountGuid,
        mailCC: this.mailCC,
        companyFullName: this.invoiceData.companyFullName,
      })
      .subscribe(
        (res) => {
          this.modalSendMail.close();
          this.launchGrowlNotificationSuccesfull();
        },
        (fail) => {
          console.log(fail);
          this.launchGrowlNotificationError();
        }
      );
  }

  async launchGrowlNotificationSuccesfull() {
    this.translate
      .get(
        this.isSaleNote ? 'SaleNoteSentSuccessfully' : 'InvoiceSentSuccessfully'
      )
      .subscribe((res: string) => {
        const notificationSuccessGrowl: any = {
          id: 0,
          title: res,
          type: 'success',
          position: 'bottom-right',
          timeout: 8000,
        };

        this.showNotificationError.showNotification(notificationSuccessGrowl);
      });
  }

  async launchGrowlNotificationError() {
    this.translate
      .get(['ErrorResendInvoice', 'ErrorResendSaleNote', 'TryAgain'])
      .subscribe((res) => {
        const notificationErrorGrowl: any = {
          id: 0,
          title: this.isSaleNote
            ? res.ErrorResendSaleNote
            : res.ErrorResendInvoice,
          text: res.TryAgain,
          type: 'error',
          position: 'bottom-right',
          timeout: 8000,
        };
        this.showNotificationError.showNotification(notificationErrorGrowl);
      });
  }

  setSelection(value: any) {
    this.selectContact = parseInt(value);
  }

  setEmailMessageSelection(value: any) {
    this.emailMessage = value;
  }

  setMailCCSelection(value: any) {
    this.mailCC = value;
  }

  closeSendMail() {
    this.modalSendMail.close();
  }

  closeDebtPayments() {
    this.modalDebtPayments.close();
  }

  closeDuesDetailModal() {
    this.duesDetailPanelModal.close();
  }

  public calculateTotal(total: any) {
    let totalPay: number = 0;
    let totalInLocalMoneyToPay: number = 0;
    this.dictionaryInput = total;
    total.forEach((item: number) => {
      if (!isNaN(item) && item != null && item != undefined) {
        totalPay += item;
        totalInLocalMoneyToPay +=
          this.invoiceData.moneySettings.calculateTotalWithExchangeValue(item);
      }
    });
    this.totalInLocalMoney = totalInLocalMoneyToPay;
    this.total = totalPay;
    this.cdref.detectChanges();
  }

  closePaymentModal() {
    this.resetPaymentModalValues();
    this.isPreviewModalOpen = false;
    return false;
  }

  resetPaymentModalValues() {
    this.dictionaryInput.clear();
  }

  async toPay() {
    if (!this.loading)
      try {
        if (
          this.totalInLocalMoney <
          this.invoiceData.moneySettings.getMinimumPaymentAmountLocal()
        ) {
          this.popUpDataTable.setValidatorToPaymentInputs();
          this.showValidationNotification();
          return;
        }
        if (this.popUpDataTable.validateAllInputs() && Number(this.total) > 0) {
          const providerPreference =
            this.dataAdapterService.providerPreferenceDataAdapt(
              this.duesTypeInfo,
              this.dictionaryInput,
              this.contact,
              this.invoiceData,
              this.invoiceData.moneySettings
            );
          this.loading = true;
          await this.redirectToPaymentGateway(providerPreference);
        }
      } catch (error: any) {
        if (error.error.errors[0].detail != undefined) {
          if (
            error.error.errors[0].detail.includes('invalid token') ||
            error.error.errors[0].detail.includes('collector_id invalid')
          ) {
            this.modalDialogErrorContent = this.translate.instant(
              'InactiveElectronicPayment'
            );
          } else {
            this.modalDialogErrorContent = this.translate.instant(
              'TimeOutPaymentGateway'
            );
          }
        } else {
          this.modalDialogErrorContent = this.translate.instant(
            'TimeOutPaymentGateway'
          );
        }
        this.modalDialogError.openModal = true;
        this.loading = false;
      }
  }

  async redirectToPaymentGateway(providerPreference) {
    const result = await this._documentViewManagerService.createPreference(
      this.encryptedData,
      providerPreference
    );
    window.open(result.initPoint, '_self');
  }

  hasPaymentWithDue(): boolean {
    return this.invoiceData.paymentsData.some(
      (payment: PaymentData) => payment.dueType == this.paymentTypeWithDue
    );
  }

  buildHeaders(): any[] {
    let headers: any[] = [];
    let isQuoteHeaderInactive =
      this.invoiceData.planWithoutAccountingSettings ||
      this.hasPaymentWithDue() == false;
    let moneyCode: string = this.invoiceData.moneySettings.getHeaderMoneyCode();
    headers = [
      {
        text: isQuoteHeaderInactive ? 'Factura' : 'Vencimiento',
        key: 'dueName',
        type: 'string',
      },
      {
        text: '# de cuota',
        key: 'dueQuote',
        type: 'number',
      },
      {
        text: isQuoteHeaderInactive ? `Valor${moneyCode}` : `Saldo${moneyCode}`,
        key: 'balance',
        type: 'number',
      },
      {
        text: `Pago o abono${moneyCode}`,
        key: 'actions',
        type: 'string',
      },
    ];

    if (isQuoteHeaderInactive) {
      headers.splice(1, 1);
    }
    return headers;
  }

  loadHeaders() {
    let header: any[] = [];
    header = this.buildHeaders();
    this.popUpDataTable.updateHeaders(header);
  }

  async loadData() {
    //* If is a plan without accounting settings
    // or if the payment type is not with due, we need to adapt the invoice data to dues model
    if (
      this.invoiceData.planWithoutAccountingSettings ||
      this.hasPaymentWithDue() == false
    ) {
      this.duesTypeInfo = this.dataAdapterService.buildDuesTypeFromInvoiceData(
        this.invoiceData
      );
    } else {
      //* otherwise, we get the dues associated with this invoice
      this.duesTypeInfo = await this.getDues();
    }
    //* we adapt the data to the table
    this.data = this.dataAdapterService.duesTypeToDataTableAdapt(
      this.duesTypeInfo
    );
    this.popUpDataTable.updateDefaultTemplate(this.data);
  }

  async loadDataDues() {
    try {
      const duesTypeInfo = await this._documentViewManagerService.getDues(
        this.encryptedData,
        this.dueName,
        this.dueConsecutive,
        this.accountCode
      );
      this.duesDetailTableData =
        this.dataAdapterService.duesTypeToDuesDetailDataTableAdapt(
          duesTypeInfo
        );
      this.duesDetailDataTable.data = this.duesDetailTableData;
    } catch (error) {
      console.log(error);
    }
  }

  async getDues(): Promise<AccountBalanceModel[]> {
    let dataBody: AccountBalanceModel[] = [];
    try {
      dataBody = await this._documentViewManagerService.getDues(
        this.encryptedData,
        this.dueName,
        this.dueConsecutive,
        this.accountCode
      );
    } catch (error) {
      console.log(error);
    }
    dataBody.map((data) => (data.moneyCode = 'COP')); //! TO DELETE
    return dataBody;
  }

  public showModalDialogClaim() {
    this.modalDialogClaim.openModal = true;
  }

  public async showCommentsModal() {
    try {
      const comments = await this._documentViewManagerService.getComments(
        this.encryptedData
      );
      const dataModalComments: any = [];

      comments.forEach((item) => {
        dataModalComments.push(
          new DataCommentsInvoice(
            item.author,
            `(${this.invoiceData.docClass}-${this.invoiceData.docCode}-${this.invoiceData.docNumber})`,
            Utils.formatFromUtcToLocalDate(item.commentDate),
            item.comment
          )
        );
      });

      this.modalComments.data = dataModalComments;
    } catch (error) {
      console.log(error);
    }
    this.modalComments.isOpenCommentsModal = true;
  }

  closeCommentsModal() {
    this.modalComments.isOpenCommentsModal = false;
  }

  public async sendComment() {
    let newCommentaryData = {
      Author: this.userCommentaryInfo.user,
      Comment: this.modalComments.valueTextArea,
      AccountCode: this.invoiceData.accountGuid,
      DocName: `${this.invoiceData.docClass}-${this.invoiceData.docCode}-${this.invoiceData.docNumber}`,
      UserCode: this.invoiceData.userCode,
    };
    try {
      await this._documentViewManagerService.createComments(
        this.encryptedData,
        newCommentaryData
      );
      this.showCommentsModal(); /*TODO: se debe remover una vez se realice el ajuste de ocultar la hora en el componente de comentarios */
    } catch (error) {
      console.log(error);
    }
  }

  async redirectToAccountStatement() {
    window.open(this.invoiceData.accountUrl, '_blank');
  }

  showValidationNotification(): void {
    const minimumPaymentValue =
      this.invoiceData.moneySettings.getMinimumPaymentAmountLocal();
    const messageKey = this.invoiceData.moneySettings.getIsLocalMoney()
      ? 'MinimumPaymentValueLocal'
      : 'MinimumPaymentValue';
    this.translate
      .get(messageKey, { minimumPaymentValue })
      .subscribe((text: string) => {
        this.notificationMessage = text;
      });

    this.notificationStatus = true;
    const alertErrorElement = this.getAlertErrorElement();
    if (alertErrorElement && alertErrorElement.classList.contains('hide')) {
      this.siigoNotificationInlineValidation.toggleNotification();
    }
  }

  private getAlertErrorElement(): HTMLElement | null {
    if (!this.siigoNotificationInlineValidation) {
      return null;
    }
    const shadowRoot = this.siigoNotificationInlineValidation['el'].shadowRoot;
    return shadowRoot ? shadowRoot.querySelector('.alert') : null;
  }

  public async downloadXml(): Promise<void> {
    if (!this.loadingDownloadFile) {
      this.loadingDownloadFile = true;
      try {
        //* get the xml files
        let filesManagement: FilesManagement =
          await this._documentViewManagerService.downloadXmlAsync(
            this.encryptedData
          );
        //* filter files with error and without error
        let filesWithoutError: FileManagement[] = filesManagement.files.filter(
          (file) => file.status == 200
        );
        let filesWithError: FileManagement[] = filesManagement.files.filter(
          (file) => file.status != 200
        );
        //* create a string to save the decoded xml
        let decodedXml: string = '';

        //* download files without error
        filesWithoutError.forEach((file) => {
          decodedXml = window.atob(file.xmlBase64);
          Utils.downloadFile(decodedXml, file.name, 'text/xml');
        });

        //* if all files have error, we show a modal with error message
        if (
          filesWithError.length > 0 &&
          filesManagement.files.length == filesWithError.length
        ) {
          //* Show modal error message
          Utils.launchCustomGrowlNotification(
            this.showNotificationError,
            this.translate.instant('ErrorDownloadXMLTitle'),
            this.translate.instant('ErrorDownloadXMLDescription'),
            TypeNotificationEnum.Error
          );
        }
        //* otherwise, if there are files with error but not all, we show a modal with warning message
        else if (filesWithError.length > 0) {
          //* translate xml types
          let xmlLabels: string[] = this.translateXmlTypes(
            filesWithError.map((file) => file.type)
          );

          //* build warning messages
          xmlLabels.forEach((label) => {
            Utils.launchCustomGrowlNotification(
              this.showNotificationError,
              this.translate.instant('WarningDownloadXMLTitle'),
              this.translate
                .instant('WarningDownloadXMLDescription')
                .replace('{invoiceXmlState}', label),
              TypeNotificationEnum.Warning
            );
          });
        }
      } catch (error) {
        //* Show modal error message
        Utils.launchCustomGrowlNotification(
          this.showNotificationError,
          this.translate.instant('ErrorDownloadXMLTitle'),
          this.translate.instant('ErrorDownloadXMLDescription'),
          TypeNotificationEnum.Error
        );
      }
      this.loadingDownloadFile = false;
    }
  }

  private translateXmlTypes(types: string[]): string[] {
    let xmlTypesLabels: string[] = types.map(
      (type) => ElectronicInvoiceStatus[type]
    );

    return xmlTypesLabels.map((label) => this.translate.instant(label));
  }
}
