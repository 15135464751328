import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpBackend, HttpClientModule } from '@angular/common/http';
import { environment } from 'src/environments/environment';
//Routing
import { RouterModule, Routes } from '@angular/router';

//Librarys
import { SiigoLibraryCoreAngularModule, UtilsService } from '@siigo/siigo-library-core-angular';

//Services
import { ServiceModule } from './services/service.module';
import { TranslationService } from './services/service.index';
import { DataAdapterService } from "./services/commons/data-adapter.service";
import { SendMailService } from "./services/send-mail.service";

//Pipes
import { FormatValues } from "./Pipes/format-values.pipe";

//Controls
import { SiigoButtonDropdownAtomLibraryModule } from "@siigo/siigo-button-dropdown-atom-angular";
import { SiigoButtonAtomLibraryModule } from "@siigo/siigo-button-atom-angular";
import { InvoiceViewPageComponent } from './pages/invoice-view-page/invoice-view-page.component';
import { InvoiceViewComponent } from './components/invoice-view/invoice-view.component';
import { ContactWithUsComponent } from './components/contact-with-us/contact-with-us.component';
import { SiigoButtonIconAtomLibraryModule } from "@siigo/siigo-button-icon-atom-angular";
import { BillingContactComponent } from './components/billing-contact/billing-contact.component';
import { AttachmentsViewComponent } from './components/attachments-view/attachments-view.component'
import { SiigoPanelAtomLibraryModule } from '@siigo/siigo-panel-atom-angular';
import { ActionHistoryComponent } from './components/action-history/action-history.component';
import { SiigoListTableAtomLibraryModule } from '@siigo/siigo-list-table-atom-angular';
import { InvoiceOptionComponent } from './components/invoice-option/invoice-option.component';
import { PortfolioBalancesComponent } from './components/portfolio-balances/portfolio-balances.component';
import { SiigoBalanceSummaryMoleculeLibraryModule } from '@siigo/siigo-balance-summary-molecule-angular';
import { SiigoPanelModalMoleculeLibraryModule } from "@siigo/siigo-panel-modal-molecule-angular";
import { SiigoDataTableMoleculeLibraryModule } from "@siigo/siigo-data-table-molecule-angular";
import { SiigoInputAtomLibraryModule } from "@siigo/siigo-input-atom-angular";
import { DataTableComponent } from "./components/data-table/data-table.component";
import { SiigoDialogModalMoleculeLibraryModule } from '@siigo/siigo-dialog-modal-molecule-angular';
import { SiigoNotificationsInlineMoleculeLibraryModule } from '@siigo/siigo-notifications-inline-molecule-angular';
import { SiigoModalCommentsMoleculeLibraryModule } from '@siigo/siigo-modal-comments-molecule-angular';
import { SiigoTextAreaAtomLibraryModule } from '@siigo/siigo-text-area-atom-angular';
import { SiigoSelectAtomLibraryModule } from '@siigo/siigo-select-atom-angular';
import { SiigoSpinnerAtomLibraryModule } from '@siigo/siigo-spinner-atom-angular';
import { ErrorViewPageComponent } from './components/error-view-page/error-view-page.component';
import { SiigoComponentLoaderAtomLibraryModule } from '@siigo/siigo-component-loader-atom-angular';
import { SiigoNotificationGrowlAtomLibraryModule } from '@siigo/siigo-notification-growl-atom-angular';
import { PaymentsConfigurationComponent } from './components/payments-configuration/payments-configuration.component';

const routes: Routes = [
  {path: 'document', component: InvoiceViewPageComponent},
  {path: 'payments-redirect-config', component: PaymentsConfigurationComponent}
];

@NgModule({
  declarations: [
    FormatValues,
    AppComponent,
    AttachmentsViewComponent,
    InvoiceViewComponent,
    BillingContactComponent,
    ContactWithUsComponent,
    InvoiceViewPageComponent,
    ActionHistoryComponent,
    InvoiceOptionComponent,
    PortfolioBalancesComponent,
    DataTableComponent,
    ErrorViewPageComponent,
    PaymentsConfigurationComponent
  ],
  imports: [
    //Esta dependencia permite activar las traducciones heredadas de siigo Nube
    // SiigoLibraryCoreAngularModule.forRoot(environment),
    BrowserModule,
    RouterModule.forRoot(routes),
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ServiceModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: environment.defaultLanguage || 'es-ES',
      loader: {
        provide: TranslateLoader,
        useClass: TranslationService,
        deps: [HttpBackend]
      },
      isolate: false
    }),
    SiigoButtonDropdownAtomLibraryModule,
    SiigoButtonAtomLibraryModule,
    SiigoButtonIconAtomLibraryModule,
    SiigoPanelAtomLibraryModule,
    SiigoListTableAtomLibraryModule,
    SiigoBalanceSummaryMoleculeLibraryModule,
    SiigoPanelModalMoleculeLibraryModule,
    SiigoDataTableMoleculeLibraryModule,
    SiigoInputAtomLibraryModule,
    SiigoDialogModalMoleculeLibraryModule,
    SiigoNotificationsInlineMoleculeLibraryModule,
    SiigoModalCommentsMoleculeLibraryModule,
    SiigoTextAreaAtomLibraryModule,
    SiigoSelectAtomLibraryModule,
    SiigoSpinnerAtomLibraryModule,
    SiigoComponentLoaderAtomLibraryModule,
    SiigoNotificationGrowlAtomLibraryModule
  ],

  providers: [
    DataAdapterService,
    SendMailService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
