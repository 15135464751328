import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Balance, InvoiceData } from 'src/app/models/documet-data.interface';
import { DataAdapterService } from 'src/app/services/commons/data-adapter.service';
import { SiigoDataTableMolecule } from '@siigo/siigo-data-table-molecule-angular';

import { DocumentViewManagerService } from '../../managers/document-view-manager.service';
import { ActivatedRoute } from '@angular/router';
import { SiigoPanelModalMolecule } from '@siigo/siigo-panel-modal-molecule-angular';
import { DebtPaymentDto, DebtPayments } from 'src/app/models/debt-payment';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { CountryISOEnum } from '@siigo/siigo-library-enums';

@Component({
  selector: 'app-portfolio-balances',
  templateUrl: './portfolio-balances.component.html',
  styleUrls: ['./portfolio-balances.component.css']
})
export class PortfolioBalancesComponent implements OnInit {
  @ViewChild('modalDebtPayments', { static: false }) modalDebtPayments: SiigoPanelModalMolecule;
  @ViewChild('duesDetailDataTable', { static: true }) dataTable: SiigoDataTableMolecule;
  @ViewChild('paymentDataTable', { static: true }) paymentDataTable: SiigoDataTableMolecule;

  @Input() balance: Balance = {}
  @Input() invoiceData: InvoiceData;
  @Input() isSaleNote: boolean;

  private encryptedData = "";
  public debtPaymentsData: DebtPayments;
  public headerTable: any;
  public listDebtPaymentsData: DebtPaymentDto[] = [];
  public dueName: string;
  public dueConsecutive: string;
  public accountCode: string;
  public data: any = {}
  public isCol: boolean;
  public isEnableNewLabel: boolean;


  constructor(
    private _dataAdapterService: DataAdapterService,
    private _documentViewManager: DocumentViewManagerService,
    private _route: ActivatedRoute,
    private _translate: TranslateService) {
    this.encryptedData = this._documentViewManager.getEncryptedData(this._route, 'data');
    this.isCol = false;
    this.isEnableNewLabel = false;
  }

  ngOnInit() {
    this.isCol = environment.countryCodeISO.toString() === CountryISOEnum.Colombia.toString();
    this.isEnableNewLabel = environment.isEnableNewLabel.toString() === 'true';
    this.loadBalanceInfo()
    this.loadDocumentInfo()    
  }

  loadDocumentInfo() {
    this.dueName = `${this.invoiceData.docClass}-${this.invoiceData.docCode}`
    this.dueConsecutive = `${this.invoiceData.docNumber}`
    this.accountCode = `${this.invoiceData.accountCode}`
  }

  loadBalanceInfo() {
    this._translate.get(['ValueInvoice', 'ValueSaleNote', 'Balance', 'InvoiceIsAboutExpire', 'ItExpired', 'ValueInvoiceNew']).subscribe(translations => {
      this.data = {
        value: {
          "title": this.isCol && this.isEnableNewLabel ? translations.ValueInvoiceNew : this.isSaleNote ? translations.ValueSaleNote : translations.ValueInvoice,
          "amount": this._dataAdapterService.numberToDueTypeFormat(
            this.balance.totalValue
          ),
          "coin": this.balance.moneyCode
        },
        status: [
          {
            "title": translations.Balance,
            "amount": this._dataAdapterService.numberToDueTypeFormat(
              this.balance.dueAction
            ),
            "coin": this.balance.moneyCode
          },
          {
            "title": translations.InvoiceIsAboutExpire,
            "amount": this._dataAdapterService.numberToDueTypeFormat(
              this.balance.valueToDue
            ),
            "status": "warning",
            "coin": this.balance.moneyCode
          },
          {
            "title": translations.ItExpired,
            "amount": this._dataAdapterService.numberToDueTypeFormat(
              this.balance.dueValue
            ),
            "status": "danger",
            "coin": this.balance.moneyCode
          }
        ]
      }
    })
  }
}
