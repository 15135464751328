import { Injectable } from '@angular/core';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  constructor() {}

  private correctImageUrl(originalUrl: string): string {
    return originalUrl.replace(/\/{2,}/g, '/').replace('https:/', 'https://');
  }

  async convertImageToBase64(url: string): Promise<string> {
    const correctedUrl = this.correctImageUrl(url);
    try {
      const response = await fetch(correctedUrl, {
        mode: 'cors',
        credentials: 'omit',
      });
      if (!response.ok) {
        throw new Error(`Error al cargar la imagen: ${response.status}`);
      }
      const blob = await response.blob();
      return await this.blobToBase64(blob);
    } catch (error) {
      console.error(
        'Error al convertir la imagen a base64:',
        error,
        correctedUrl
      );
      throw error;
    }
  }

  blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  async replaceImagesWithBase64(element: HTMLElement): Promise<void> {
    const images = Array.from(element.querySelectorAll('img'));
    await Promise.all(
      images.map(async (img) => {
        const src = img.getAttribute('src');
        if (!src) return;
        img.setAttribute('crossorigin', 'anonymous');
        try {
          const base64 = await this.convertImageToBase64(src);
          img.src = base64;
        } catch (error) {
          console.error('Error al cargar imagen como base64:', error, src);
        }
      })
    );
  }

  public async downloadHtml(content: string, filename: string) {
    content = this.removePrintStyles(content);
    const styles = this.getStylesPrint(content);

    const iframe = document.createElement('iframe');
    iframe.style.position = 'fixed';
    iframe.style.width = '725px';
    iframe.style.height = '843px';
    iframe.style.left = '0';
    iframe.style.top = '0';
    iframe.style.zIndex = '-1';
    iframe.style.visibility = 'hidden';
    iframe.style.backgroundColor = 'white';
    iframe.style.overflow = 'hidden';

    document.body.appendChild(iframe);

    const iframeDocument =
      iframe && iframe.contentWindow && iframe.contentWindow.document;
    if (iframeDocument) {
      iframeDocument.open();
      iframeDocument.write(`
        <html>
          <head>
          <style type="text/css">
          .invoice-container.rounded-container {
              border-top-width: 1px !important;
              border-right-width: 0px !important;
              border-bottom-width: 1px !important;
              border-left-width: 1px !important;
              border-style: solid !important;
              border-color: #ccc !important;
              border-radius: 10px !important;
            }
          </style>
          <style>${styles}
          </style></head>
          <body>
            ${content}
          </body>
        </html>
      `);
      iframeDocument.close();

      try {
        await this.replaceImagesWithBase64(iframeDocument.body);

        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        let scale = isMobile ? 1.5 : 2;

        const canvas = await html2canvas(iframeDocument.body, {
          scale,
          width: iframeDocument.body.scrollWidth,
          height: iframeDocument.body.scrollHeight,
          useCORS: true,
          allowTaint: false,
        });

        const imgHeight = canvas.width * (pdfHeight / pdfWidth);
        let position = 0;

        const canvasPage = document.createElement('canvas');
        const pageCtx = canvasPage.getContext('2d');

        while (position < canvas.height) {
          canvasPage.width = canvas.width;
          canvasPage.height = imgHeight;

          if (pageCtx) {
            pageCtx.clearRect(0, 0, canvasPage.width, canvasPage.height);
            pageCtx.drawImage(
              canvas,
              0,
              position,
              canvas.width,
              imgHeight,
              0,
              0,
              canvasPage.width,
              imgHeight
            );
            const imgData = canvasPage.toDataURL('image/png');
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
          }
          position += imgHeight;
          if (position < canvas.height) {
            pdf.addPage();
          }
        }

        pdf.save(filename);
      } catch (error) {
        console.error('Error al crear el PDF:', error);
      } finally {
        document.body.removeChild(iframe);
      }
    }
  }

  private removePrintStyles(content: string): string {
    return content.replace(/@media\s+print[^{]*\{[^}]*\}/g, '');
  }

  public getStylesPrint(html: string): string {
    let styles = '<style type="text/css"> @media print { ';
    styles += '     .invoice-container { height: initial !important; } ';
    styles += ` .invoice-container.rounded-container {
   background-color: red !important;
    }`;
    styles += '     .invoice-box { height: inherit !important; } ';
    styles += '     .Numeric { width: inherit; } ';
    styles +=
      '     .invoice-container-left { padding-right: 10px !important; } ';
    styles +=
      '     .invoice-container-right { padding-right: 20px !important; } ';
    styles += '     .TotalContent { display: inline-table; } ';
    styles += '     .TableDocumentDetail { bottom: 0px !important; } ';
    styles += '     .UserDetails { min-width: 150px; } ';

    if (html.includes('ribbon_empty.png')) {
      styles += '     .container-invoice>table>tbody>tr>td>div>img ';
      styles +=
        '     .invoice-container-left>table>tbody>tr>td>div>img { opacity: 0;  } ';
      styles +=
        '     .invoice-container-left>table>tbody>tr>td>div>img[alt="QrCode"] { opacity: 1 !important;  } ';
      styles += '     .UserLogo>img { opacity: 1 !important; } ';
    }

    styles += ' } </style > ';

    return styles;
  }
}
