import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Historical } from 'src/app/models/documet-data.interface';
import { Utils } from 'src/app/utils/utils';

@Component({
  selector: 'action-history',
  templateUrl: './action-history.component.html',
  styleUrls: ['./action-history.component.css']
})
export class ActionHistoryComponent implements OnInit {

  @Input() histories: Historical[]

  constructor() { }

  dateFormat(date : any) {
    return Utils.formatDateHistory(date);
  }

  ngOnInit() {
  }

}
