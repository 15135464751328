export const environment = {
  production: false,
  defaultLanguage: 'es-ES',
  pathAssets: './assets/',
  CDNSiigoBase: 'https://d2gh2oz6jtmsd4.cloudfront.net/',
  contactwithus:
    'https://www.siigo.com/facturacion-electronica/?ppc=1&medio_virtual=ISIIgo%20Factura&utm_campaign=Link%20FacturaSiigo&utm_medium=ISIIgo%20Factura&utm_campana=Link%20FacturaSiigo&cmp=Link%20FacturaSiigo',
  publicDocumentViewEndpoint:
    'https://servicesqa.siigo.com/alliances/api/public-document-view/',
  publicDocumentViewEndpointV2:
    'https://servicesqa.siigo.com/alliances/api/v2/public-document-view/',
  decimalDigit: 2,
  decimalSeparator: '.',
  currencyLanguageFormat: 'en-US',
  currencyCharacterBalance: '$',
  defaultQuantityMercadoPago: 1,
  token: 'Y2U2ZmJ.hOGItOTMyMC00MGVjLWE5NWUtNTNkMTcy',
  minimumInvoiceBalance: 1000,
  countryCodeISO: '170',
  isEnableNewLabel: 'true',
  imageUrl: 'https:/monolithqa.siigo.com',
};
